.navbar-nav .nav-item .nav-link img {
    height: 110px; /* Adjust the height of the image */
  }
  
  .navbar-logo {
    width: auto; /* Allow the image to resize while maintaining its aspect ratio */
    height: 100%; /* Make the image fill the entire height of its parent container */
  }
  
  .black-text {
    color: black !important;
    padding-top: 20px;
  }
  
  .black-text:hover {
    color: grey !important;
  }
  
  .nav-options {
    text-decoration: none;
    color: rgb(4, 4, 4);
    font-size: 22px;
    font-weight: bold;
    display: flex;
  align-items: center;
  }
  
  .navbar1 {
    height: 35px;
  }
  
  .navbar2 {
    height: 110px;
    background-image: url('./head-Banner.png');
    /* background-color: antiquewhite; */
  }

  .p-3{
    padding: 0px;
  }
  
  @media screen and (max-width: 768px) {
    .navbar1,
    .navbar2 {
      height: auto; /* Allow the height to adjust based on content */
      margin-top: 0; 
    }
  
    .navbar-nav {
      flex-direction: row !important; /* Ensure nav items stay in a single row */
    }
  
    .navbar-nav .nav-item {
      margin-right: 10px; /* Adjust margin between nav items */
    }
  
    .navbar-nav .nav-item:last-child {
      margin-right: 0; /* Remove margin from last nav item */
    }
  
    .navbar-nav .nav-link {
      padding-top: 10px; /* Adjust padding for smaller screens */
      padding-bottom: 10px; /* Adjust padding for smaller screens */
    }
  
    .navbar2 .navbar-toggler {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 9999; /* Ensure the toggler is on top of other content */
    }
  }
  
  .title {
    font-size: 2.5rem;
    font-family: "Lobster", cursive;
  }
  
  .carousel-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Adjust the opacity as needed */
    z-index: 0;
  }
  
  .navbar-toggler-icon.custom-toggler {
    color: black !important;
  }
  
  .centered-caption {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  

  

  
