.carousel-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust the opacity as needed */
  z-index: 0;
}

.navbar-toggler-icon.custom-toggler {
  color: black !important;
}

.centered-caption {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.card-img-top {
    transition: transform 0.4s ease;
  }

.navbar-logo {
    width: auto; /* Allow the image to resize while maintaining its aspect ratio */
    height: 100%; /* Make the image fill the entire height of its parent container */
  }

  .container{
    margin-top: 40px;
}

  .card {
    overflow: hidden;
    border: 0px;
    background-image: url('./banner3.png');
  }

.card-img-top {
    transition: transform 0.4s ease;
  }
  
  .card-img-top:hover {
    transform: scale(1.10); /* Adjust the scale value as needed */
  }

  .future-head {
    text-align: left;
  height: 50px; 
  display: flex;
  flex-direction: column;
  justify-content: left;
}

.underline {
    border-bottom: 2px solid brown;
    width: 100px; 
    margin-top: 15px; 
  }

  @media (max-width: 750px) {
    .after-2-cards{
      margin-top: 5px;
    }
    .img-after-cat{
      height: 230px;
    }
  }
